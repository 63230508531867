<template>
  <div class="c-app">
    <TheSidebar/>
    <div class="c-wrapper">
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </div>
    <Loading />
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import Loading from '@/components/Loading'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Loading
  }
}
</script>

<style lang="scss" scoped>
.c-body{
  .c-main{
    padding-top: 10px;
    background-color: #e4e5e7;
    .container-fluid{
      padding: 0;
      .childpool-wrap,
      .setting-wrap{
        padding: 0 24px;
        .row{
          margin: 0;
        }
      }
      .row{
        margin: 0;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
