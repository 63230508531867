<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <span class="avatar-name">{{ name }}</span>
          <div class="avatar-img">
            <img src="@/assets/images/avatar.png" class="c-avatar-img" />
          </div>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{$t("THE_HEADER_DROPDOWN_ACCOUNT_CONTAINER_LABEL_ACCOUNT")}}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="goProfile">
      <CIcon name="cil-user" />{{$t("THE_HEADER_DROPDOWN_ACCOUNT_CONTAINER_LABEL_PROFILE")}}
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" />{{$t("THE_HEADER_DROPDOWN_ACCOUNT_CONTAINER_LABEL_LOGOUT")}}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";
import endpoints from "@/constants/endpoints";
import { mapState } from "vuex";

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
    };
  },
  computed: {
    ...mapState({
      name: state => state.auth.userName
    })
  },
  methods: {
    goProfile() {
      this.$router.push("/admin/profile");
    },
    async logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    }
  }
};
</script>

<style scoped lang="scss">
.c-icon {
  margin-right: 0.3rem;
}
.c-avatar{
  width: auto;
  .avatar-name{
    margin-right: 10px;
    color: #4F5363;
    // font-family: "SF UI Display";
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  .avatar-img{
    width: 36px;
    height: 36px;
  }
}
</style>
