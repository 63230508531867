<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none pt-2 pb-2"
      src="https://www.infinito.io/wp-content/themes/b2b/assets/images/logo.svg"
      width="100"
      alt="CoreUI Logo"
    />

    <CHeaderNav class="mr-4 ml-auto">
      <ChangeLanguage />
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import ChangeLanguage from '@/components/ChangeLanguage';
export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    ChangeLanguage
  },
  data() {
    return {
      breadCrumbs: []
    };
  },
  watch: {},
  mounted() {}
};
</script>
<style lang="scss">
  .c-header-nav{
    min-height: 60px;
  }
  .breadcrumb{
    margin: 0;
  }
  header{
    button:focus{
      outline: none;
    }
  }
</style>