import responseHelper from '@/utils/response-helper';
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_STAKING_PLATFORM'),
        to: '/admin/staking-platform',
        permissionName: 'VIEW_LIST_STAKING_PLATFORM',
        key: 'STAKING_PLATFORM',
        icon: 'cil-puzzle'
      },
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_CHILDPOOL'),
        to: '/admin/childpool',
        permissionName: 'VIEW_LIST_CHILD_PARTNER',
        key: 'CHILDPOOL',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_USER_MANAGEMENT'),
        to: '/admin/user-management',
        permissionName: 'VIEW_LIST_USER',
        key: 'USER_MANAGEMENT',
        icon: 'cil-user'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_SETTING'),
        route: '/admin/setting',
        key: 'SETTING',
        icon: 'cil-settings',
        items: [
          {
            name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_DISTRIBUTE_COMMISSION'),
            to: '/admin/setting/distribute-commission',
            permissionName: 'VIEW_LIST_COMMISSION_SETTING',
            key: 'DISTRIBUTE_COMMISSION',
          },
          {
            name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_COLD_WALLETS'),
            to: '/admin/setting/cold-wallet',
            permissionName: 'ROOT',
            key: 'COLD_WALLETS',
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_ROLE_MANAGEMENT'),
        to: '/admin/roles',
        permissionName: 'ROOT',
        key: 'ROLE_MANAGEMENT',
        icon: 'cil-puzzle'
      },
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_REWARD'),
        to: '/admin/reward',
        permissionName: 'VIEW_TOTAL_REWARD',
        key: 'TOTAL_REWARD',
        icon: 'cil-money'
      },
      {
        _name: 'CSidebarNavItem',
        name: responseHelper.getMessageByCode('THE_SIDEBAR_CONTAINER_REVENUE'),
        to: '/admin/revenue',
        permissionName: 'VIEW_TOTAL_REWARD',
        key: 'TOTAL_REWARD',
        icon: 'cil-money'
      },
      // {
      //   _name: "CSidebarNavTitle",
      //   _children: ["Theme"]
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Colors",
      //   to: "/admin/theme/colors",
      //   icon: "cil-drop"
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Typography",
      //   to: "/admin/theme/typography",
      //   icon: "cil-pencil"
      // },
      // {
      //   _name: "CSidebarNavTitle",
      //   _children: ["Components"]
      // },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Base",
      //   route: "/admin/base",
      //   icon: "cil-puzzle",
      //   items: [
      //     {
      //       name: "Breadcrumbs",
      //       to: "/admin/base/breadcrumbs",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Cards",
      //       to: "/admin/base/cards",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Carousels",
      //       to: "/admin/base/carousels",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Collapses",
      //       to: "/admin/base/collapses",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Forms",
      //       to: "/admin/base/forms",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Jumbotrons",
      //       to: "/admin/base/jumbotrons",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "List Groups",
      //       to: "/admin/base/list-groups",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Navs",
      //       to: "/admin/base/navs",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Navbars",
      //       to: "/admin/base/navbars",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Paginations",
      //       to: "/base/paginations",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Popovers",
      //       to: "/admin/base/popovers",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Progress Bars",
      //       to: "/admin/base/progress-bars",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Switches",
      //       to: "/admin/base/switches",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Tables",
      //       to: "/admin/base/tables",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Tabs",
      //       to: "/admin/base/tabs",
      //       icon: "cil-puzzle"
      //     },
      //     {
      //       name: "Tooltips",
      //       to: "/admin/base/tooltips",
      //       icon: "cil-puzzle"
      //     }
      //   ]
      // }
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Buttons",
      //   route: "/admin/buttons",
      //   icon: "cil-cursor",
      //   items: [
      //     {
      //       name: "Buttons",
      //       to: "/admin/buttons/standard-buttons",
      //       icon: "cil-cursor"
      //     },
      //     {
      //       name: "Button Dropdowns",
      //       to: "/admin/buttons/dropdowns",
      //       icon: "cil-cursor"
      //     },
      //     {
      //       name: "Button Groups",
      //       to: "/admin/buttons/button-groups",
      //       icon: "cil-cursor"
      //     },
      //     {
      //       name: "Brand Buttons",
      //       to: "/admin/buttons/brand-buttons",
      //       icon: "cil-cursor"
      //     }
      //   ]
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Charts",
      //   to: "/admin/charts",
      //   icon: "cil-chart-pie"
      // },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Icons",
      //   route: "/admin/icons",
      //   icon: "cil-star",
      //   items: [
      //     {
      //       name: "CoreUI Icons",
      //       to: "/admin/icons/coreui-icons",
      //       icon: "cil-star",
      //       badge: {
      //         color: "info",
      //         text: "NEW"
      //       }
      //     },
      //     {
      //       name: "Brands",
      //       to: "/admin/icons/brands",
      //       icon: "cil-star"
      //     },
      //     {
      //       name: "Flags",
      //       to: "/admin/icons/flags",
      //       icon: "cil-star"
      //     }
      //   ]
      // },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Notifications",
      //   route: "/admin/notifications",
      //   icon: "cil-bell",
      //   items: [
      //     {
      //       name: "Alerts",
      //       to: "/admin/notifications/alerts",
      //       icon: "cil-bell"
      //     },
      //     {
      //       name: "Badges",
      //       to: "/admin/notifications/badges",
      //       icon: "cil-bell"
      //     },
      //     {
      //       name: "Modals",
      //       to: "/admin/notifications/modals",
      //       icon: "cil-bell"
      //     }
      //   ]
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Widgets",
      //   to: "/admin/widgets",
      //   icon: "cil-calculator",
      //   badge: {
      //     color: "primary",
      //     text: "NEW",
      //     shape: "pill"
      //   }
      // },
      // {
      //   _name: "CSidebarNavDivider",
      //   _class: "m-2"
      // },
      // {
      //   _name: "CSidebarNavTitle",
      //   _children: ["Extras"]
      // },
      // {
      //   _name: "CSidebarNavDropdown",
      //   name: "Pages",
      //   route: "/pages",
      //   icon: "cil-star",
      //   items: [
      //     {
      //       name: "Login",
      //       to: "/login",
      //       icon: "cil-star"
      //     },
      //     {
      //       name: "Register",
      //       to: "/register",
      //       icon: "cil-star"
      //     },
      //     {
      //       name: "Error 404",
      //       to: "/404",
      //       icon: "cil-star"
      //     },
      //     {
      //       name: "Error 500",
      //       to: "/500",
      //       icon: "cil-star"
      //     }
      //   ]
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Download CoreUI",
      //   href: "http://coreui.io/vue/",
      //   icon: { name: "cil-cloud-download", class: "text-white" },
      //   _class: "bg-success text-white",
      //   target: "_blank"
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Try CoreUI PRO",
      //   href: "http://coreui.io/pro/vue/",
      //   icon: { name: "cil-layers", class: "text-white" },
      //   _class: "bg-danger text-white",
      //   target: "_blank"
      // }
    ]
  }
];
