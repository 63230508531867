<template>
  <CFooter>
    <div>
<!--      <a href="https://coreui.io" target="_blank">Infinito</a>-->
      <span class="mg-15">Moonstake © 2020</span>
    </div>
    <div class="ml-auto">
      <span>Staking Pool Admin</span>
    </div>
  </CFooter>
</template>

<script>
  export default {
    name: "TheFooter"
  };
</script>
