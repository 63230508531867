<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <!-- <CSidebarBrand
      to="/admin"
      :imgFull="{
        width: 118,
        height: 46,
        alt: 'Logo',
        src:
          'https://www.infinito.io/wp-content/themes/b2b/assets/images/logo.svg'
      }"
      :imgMinimized="{
        width: 118,
        height: 46,
        alt: 'Logo',
        src:
          'https://www.infinito.io/wp-content/themes/b2b/assets/images/logo.svg'
      }"
      :wrappedInLink="{ href: 'https://infinito.io', target: '_blank' }"
    />-->
    <CSidebarBrand to="/admin" class="side-bar">
      <img v-if="minimize===false" src="@/assets/images/logo-moonstake-white.svg" alt />
      <span v-else>B</span>
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>
</template>

<script>
import _ from 'lodash';
import nav from './_nav';
import { mapState } from 'vuex';

export default {
  name: 'TheSidebar',
  data() {
    return {
      minimize: false,
      nav: [],
      show: 'responsive',
    };
  },
  computed: {
    ...mapState({
      role: (state) => (state.auth ? state.auth.role : null),
      userPermission: (state) => (state.auth ? state.auth.userPermission : null),
    }),
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.userPermission,
      (newValue, oldValue) => {
        const userPermission = newValue || [];
        this.updateSidebar(userPermission);
      },
    );
    this.updateSidebar(this.userPermission);
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive';
      this.show = sidebarOpened ? false : 'responsive';
    });
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false;
      this.show = sidebarClosed ? true : 'responsive';
    });
  },
  methods: {
    updateSidebar(userPermissions) {
      if (!userPermissions.length) {
        this.nav = [];
        return;
      }

      const cachePermission = userPermissions.reduce((result, item) => {
        result[item.name] = result;

        return result;
      }, {});

      nav[0]._children.forEach((i) => {
        if (!i.oldName) {
          i.oldName = i.name;
        }
        i.name = this.$t(i.oldName);

        if (i.items) {
          i.items.forEach((e) => {
            if (!e.oldName) {
              e.oldName = e.name;
            }
            e.name = this.$t(e.oldName);
          });
        }
      });

      const nav2 = _.cloneDeep(nav);
      const menuItems = nav2[0]._children;
      this.removeMenuItems(menuItems, cachePermission);

      this.nav = nav2;
    },
    removeMenuItems(menuItems, cachePermission) {
      for (let index = 0; index < menuItems.length; ) {
        const item = menuItems[index];
        const { permissionName, key, items } = item;
        // console.log(index, permissionName, key, !!cachePermission[permissionName]);
        if (permissionName && !cachePermission[permissionName]) {
          _.remove(menuItems, (e) => e.key === key);
          continue;
        }

        if (!items) {
          index++;
          continue;
        }

        this.removeMenuItems(items, cachePermission);
        if (!items.length) {
          _.remove(menuItems, (e) => e.key === key);
        } else {
          index++;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.side-bar {
  font-size: 30px;
  font-weight: bold;
  flex: 0 0 60px;
}
.c-sidebar {
  .c-sidebar-brand {
    background-color: #1a2958;
  }
  .c-sidebar-nav {
    background-color: #3b4b7e;
    .c-sidebar-nav-link {
      // font-family: "SF UI Display";
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
    .c-sidebar-nav-link:hover,
    .c-sidebar-nav-dropdown-toggle:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}
</style>
